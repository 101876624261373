* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: $blue;
  transition: all 250ms;

  &:hover {
    color: $font-color;
  }
}

h2 {
  margin-bottom: 20px;
}

body {
  background: $background-color;
  color: $font-color;
  font-family: Helvetica, Arial, sans-serif;
}

.App {
  width: 100%;
  margin: 0 auto;
  padding: 20px;

  @include md {
    display: flex;
    padding: 30px;
  }

  @include lg {
    padding: 40px;
  }

  @include xl {
    padding: 50px;
  }
}

.Image {
  margin-top: 20px;
  max-height: calc(100vh - 40px);

  @include md {
    max-height: calc(100vh - 60px);
  }

  @include lg {
    max-height: calc(100vh - 80px);
  }

  @include xl {
    max-height: calc(100vh - 100px);
  }

  @include md {
    margin-top: 0;
    flex-grow: 1;
  }
}
