.Controls {
  flex-basis: 270px;
  flex-shrink: 0;
  z-index: 10;
  position: relative;

  @include md {
    margin-right: 30px;
  }

  @include lg {
    margin-right: 40px;
  }

  @include xl {
    margin-right: 50px;
  }
}

.Control {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
  align-items: center;
}

.Control-label {
  flex-basis: 100%;
  margin-bottom: 5px;
}

.Control-note {
  color: #999;
  font-size: 12px;
  margin: 7px 0;
}

.Control-inputWrapper {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: calc(100% - 140px);
  margin-right: auto;

  input:not([type=checkbox]) {
    width: 100%;
  }
}

.Controls-buttons {
  margin-top: 20px;
}

.Control-value {
  flex-basis: 50px;
  text-align: right;
  flex-shrink: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Controls-title {
  font-size: 26px;
  margin-bottom: 10px;
  display: inline-block;

  span {
    transition: all 250ms;
  }
}

.Controls-titleLink {
  text-decoration: none;
}

.Controls-description {
  margin-bottom: 20px;
  
  p {
    margin-bottom: 10px;
  }
  
  a {
    margin-right: 10px;
  }
}