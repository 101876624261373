.Image-svg {
  overflow: visible;
  display: block;
  border: 1px solid $border-color;
  width: auto;
  height: auto;

  @include lg {
    height: 100%;
    max-width: 100%;
  }
}

.Image-generateDownload {
  margin: 20px 0 40px;
  display: block;
}