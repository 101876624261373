$breakpoint-md: 800px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1500px;


@mixin md {
  @media (min-width: #{$breakpoint-md}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$breakpoint-lg}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$breakpoint-xl}) {
    @content;
  }
}

